<template>
  <div class="w-full lg:w-11/12 mx-auto bg-bg_color rounded-lg py-1 mt-1 mb-4">
    <div
      class="flex relative items-center md:static px-1"
      :class="{ 'cursor-pointer': !isPrimaryAd }"
      @click="!isPrimaryAd ? goToRelated() : null"
    >
      <!-- Ad Meta -->
      <div
        v-if="isPrimaryAd"
        class="absolute inset-x-0 top-1/2 -translate-y-1/2 md:top-auto md:translate-y-0 md:w-1/3 md:static backdrop-blur-sm p-1 sm:p-2"
      >
        <div class="flex flex-col items-center justify-center">
          <div class="md:text-xl lg:text-2xl font-bold text-text_color">
            {{ ad.title }}
          </div>

          <div
            class="hidden sm:block md:text-lg lg:text-xl text-text_color font-semibold md:font-normal"
          >
            {{ ad.sub_title }}
          </div>
          <div class="h-1 sm:h-5 md:h-10"></div>
          <button
            v-if="ad.button_title"
            class="bg-nav_color rounded-md text-sm md:text-base text-text_alt_color py-0.5 md:py-1 md:px-5 px-3"
            @click="goToRelatedAd(ad)"
          >
            {{ ad.button_title }}
          </button>
        </div>
      </div>

      <!-- Ad Image -->
      <div
        class="w-full"
        :class="[{ 'md:w-2/3': isPrimaryAd }, `aspect-[${width / height}]`]"
      >
        <NuxtImg
          provider="cacheIpx"
          preset="modified"
          loading="lazy"
          :src="ad.image"
          :alt="ad.title"
          :width="width"
          :height="height"
          :sizes="`sm:${width * 1.5}px md:${width * 2}px lg:${width * 3}px`"
          class="w-full h-full object-cover rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ad } from '~~/composables/useMenuModel'

const props = withDefaults(
  defineProps<{
    ad: Ad
    width: number
    height: number
  }>(),
  {
    width: 1800,
    height: 720
  }
)
const isPrimaryAd = computed(() => {
  return props.ad.location === 1
})
const { goToRelatedAd } = useAnnouncementStore()

function goToRelated() {
  goToRelatedAd(props.ad)
}

</script>
